import { tokenService } from 'src/services/token.service';
import {
  AUTH_AVATAR_UPDATE,
  AUTH_LOGIN_SUCCESS,
  AUTH_INFO_LOADED,
  LOGIN_STATUS_CHANGED,
  GET_PROFILE_DONE,
} from '../actions';

const INIT_STATE = {
  login: tokenService.getLocalLogin(),
  username: '',
  active: false,
  role: '',
  credits: 0,
  loading: false,
  message: '',
  info: { user_name: 'Admin' },
  permission: {},
  avatar: '/assets/img/no_profile.png',
  apiEnabled: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_STATUS_CHANGED:
      const { login, role, username, active } = action.payload;
      return { ...state, role, login, username, active };
    case GET_PROFILE_DONE:
      const { credits, total_credits } = action.payload;
      return {
        ...state,
        credits: Number(credits),
        total_credits: Number(total_credits),
        apiEnabled: action.payload.api_enabled,
      };
    case AUTH_LOGIN_SUCCESS:
      const { status, message, data, permission } = action.payload;
      return { ...state, login: status, message, info: data || {}, permission };
    case AUTH_INFO_LOADED:
      return { ...state, info: action.payload };
    case AUTH_AVATAR_UPDATE:
      return { ...state, avatar: action.payload };
    default:
      return state;
  }
};
