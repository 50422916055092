import api from './api';

export class ProfileService {
  updatePassword = ({ oldPassword, password }) => {
    return api
      .post('/admin/me/update-password', { password, oldPassword })
      .then((res) => res.data);
  };

  get = () => {
    return api.get('/admin/me').then((res) => res.data);
  };
}

export const profileService = new ProfileService();
