import {
  AUTH_LOAD_INFO,
  AUTH_LOGOUT,
  LOGIN_INIT_FROM_LOCAL,
  GET_PROFILE,
} from '../actions';

export const initLoginFromLocal = () => ({
  type: LOGIN_INIT_FROM_LOCAL,
});

export const logout = (data) => ({
  type: AUTH_LOGOUT,
  payload: data,
});

export const loadAuthInfo = () => ({
  type: AUTH_LOAD_INFO,
});

export const getProfile = () => ({
  type: GET_PROFILE,
});
