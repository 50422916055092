/* eslint-disable import/no-cycle */
/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_INIT_FROM_LOCAL = 'LOGIN_USER_FROM_LOCAL';
export const LOGIN_STATUS_CHANGED = 'LOGIN_STATUS_CHANGED';
export const LOGOUT_USER = 'LOGOUT_USER';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILE_DONE = 'GET_PROFILE_DONE';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOAD_INFO = 'AUTH_LOAD_INFO';
export const AUTH_INFO_LOADED = 'AUTH_INFO_LOADED';
export const AUTH_AVATAR = 'AUTH_AVATAR';
export const AUTH_AVATAR_UPDATE = 'AUTH_AVATAR_UPDATE';

export const ADMIN_NOTI_GET = 'ADMIN_NOTI.GET';
export const ADMIN_NOTI_ARRIVED = 'ADMIN_NOTI.ARRIVED';

export const USERS_GET = 'USERS_GET';
export const USERS_ARRIVED = 'USERS_ARRIVED';

export * from './menu/actions';
export * from './settings/actions';

export * from './admin-notification/actions';
export * from './auth/actions';
export * from './users/actions';
