import { all } from 'redux-saga/effects';
// import authSagas from './auth/saga';

import adminNotiSaga from './admin-notification/saga';
import authSaga from './auth/saga';
import userSaga from './users/saga';

export default function* rootSaga(getState) {
  yield all([adminNotiSaga(), authSaga(), userSaga()]);
}
