import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';

import adminNotifications from './admin-notification/reducer';
import auth from './auth/reducer';
import users from './users/reducer';

const reducers = combineReducers({
  menu,
  settings,

  adminNotifications,
  auth,
  users,
});

export default reducers;
